import '../layout/index.scss'

import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {graphql} from 'gatsby'
import React from 'react'
import {PageQueryQuery} from 'types/graphtypes'
import {Page} from 'layout/pages/page/page'
import {TemplateType} from 'types/basictypes'
import {Template} from 'layout/templates/template'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

const PagePage: React.FC<TemplateType<PageQueryQuery>> = ({
	data,
	pageContext
}) => {
	const {
		page: {
			path: {langcode},
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_blocks
			}
		},
		allPagePreview: { relatedFragments },
		allNodeJob: { relatedJobsFragments }
	} = data

	const preview = previewFragmentToPreviewItem(previewFragment)
	const blocks = loadBlocksFromGraph(field_blocks)

	return (
		<Template
			preview={preview}
			lang={langcode}
			pageContext={pageContext}
			relatedFragments={relatedFragments}
			relatedJobsFragments={relatedJobsFragments}
			content={blocks}
			metaFragment={metaFragment}
		>
			<Page
				blocks={blocks}
			/>
		</Template>
	)
}

export default PagePage

export const pageQuery = graphql`
	query PageQuery($slug: String!, $allTags: [String]) {
		page: nodePage(fields: {slug: {eq: $slug}}) {
			path {
				langcode
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_blocks {...Blocks}
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {tags: {in: $allTags}, has_image: {eq: true}}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}

		allNodeJob(
			filter: {
				field_job_status: {eq: true}
				relationships: {field_tags: {elemMatch: {name: {in: $allTags}}}}
			}
			limit: 4
			sort: {fields: field_date, order: DESC}
		) {
			relatedJobsFragments: nodes {
				...JobPreview
			}
		}
	}
`
